import {Box, Typography, UnstyledHtmlAnchor} from '@ttx/design-system';
import {capitalize} from '@ttx/design-system/src/nft-details-panel/utils';

export const MAX_NFT_GRID_COLUMNS = [2, 3, 3, 6] as [
  number,
  number,
  number,
  number,
];
export const MIN_NFT_GRID_COLUMNS = [1, 2, 2, 4] as [
  number,
  number,
  number,
  number,
];

export const PRODUCTION_URL = 'https://tokentraxx.com';
export const TESTNET_URL = 'https://app.testnet.tokentraxx.com';
export const CONTENT_MAX_WIDTH = '2000px';

export const STUDIO_MENUS = {
  'wild-awake': {
    title: 'Openlab',
    navbarBg: '#FFF',
    backgroundThemeColor: '#FF6C74',
    textColor: '#FFF',
    logo: 'https://openlab.fm/img/openlab-logo.svg',
    fonts: {
      altone: 'Altone, apple-system, sans-serif',
      abc: 'ABC Diatype Semi Mono, apple-system, sans-serif',
      abcHeading: 'ABC Diatype, apple-system, sans-serif',
      times: 'Times New Roman, Georgia, Garamond',
    },
    buttonBGColor: '#FF6C74',
    buttonColor: '#FFF',
    contact: '',
    footerUsefulLinks: [],
    footerSocialURLs: [],
    homeLink: '',
    height: '55px',
  },
  openlab: {
    title: 'Openlab',
    navbarBg: '#FFF',
    backgroundThemeColor: '#FF6C74',
    textColor: '#FFF',
    logo: 'https://openlab.fm/img/openlab-logo.svg',
    fonts: {
      altone: 'Altone, apple-system, sans-serif',
      abc: 'ABC Diatype Semi Mono, apple-system, sans-serif',
      abcHeading: 'ABC Diatype, apple-system, sans-serif',
      times: 'Times New Roman, Georgia, Garamond',
    },
    buttonBGColor: '#FF6C74',
    buttonColor: '#FFF',
    contact: 'info@openlab.fm',
    footerUsefulLinks: [
      {
        title: 'Radio',
        url: 'https://openlab.fm/radio/on-air',
      },
      {title: 'Visual', url: 'https://openlab.fm/visuals/art-and-photography'},
      {title: 'NFT', url: 'https://openlab.fm/nft'},
      {title: 'News', url: 'https://openlab.fm/news'},
      {
        title: 'Shop',
        url: 'https://shop.openlab.fm/',
      },
      {
        title: 'About',
        url: 'https://openlab.fm/about/what-is-openlab/introduction-to-openlab',
      },
    ],
    footerSocialURLs: [],
    homeLink: 'https://openlab.fm/',
    height: '55px',
  },
  'the-drop': {
    title: 'The Drop',
    navbarBg: '#141414',
    backgroundThemeColor: '#141414',
    textColor: '#FFF',
    logo: 'https://traxx-studio.s3.amazonaws.com/The-Drop-Logo-Hz-9.png',
    fonts: {
      altone: 'Altone, apple-system, sans-serif',
      abc: 'ABC Diatype Semi Mono, apple-system, sans-serif',
      abcHeading: 'ABC Diatype, apple-system, sans-serif',
      times: 'Times New Roman, Georgia, Garamond',
    },
    buttonBGColor: '#476E55',
    buttonColor: '#FFF',
    contact: 'contact@tokentraxx.com',
    footerUsefulLinks: [
      {
        title: 'Watch',
        url: 'https://thedrop.movie/',
      },
      {title: 'Trailers', url: 'https://thedrop.movie/movies/'},
      {title: 'Shop', url: 'https://thedrop.movie/shop/'},
    ],
    footerSocialURLs: [],
    homeLink: 'https://thedrop.movie/',
    height: '55px',
  },
  'here-at-last': {
    title: 'Here At Last',
    navbarBg: '#000',
    backgroundThemeColor: '#039BE5',
    textColor: '#FFF',
    logo: 'https://traxx-studio.s3.amazonaws.com/HAL+OMBRE+2.png',
    fonts: {
      altone: 'Altone, apple-system, sans-serif',
      abc: 'ABC Diatype Semi Mono, apple-system, sans-serif',
      abcHeading: 'ABC Diatype, apple-system, sans-serif',
      times: 'Times New Roman, Georgia, Garamond',
    },
    buttonBGColor: '#039BE5',
    buttonColor: '#FFF',
    contact: 'contact@tokentraxx.com',
    footerUsefulLinks: [
      {
        title: 'Openstage',
        url: 'https://hereatlast.os.fan/',
      },
    ],
    footerSocialURLs: [],
    homeLink: 'https://hereatlast.os.fan/',
    height: '55px',
  },
  'my-sahel': {
    title: 'My Sahel',
    navbarBg: '#000',
    backgroundThemeColor: '#039BE5',
    textColor: '#FFF',
    logo: 'https://www.datocms-assets.com/73624/1692186826-4-logo.png',
    fonts: {
      altone: 'Altone, apple-system, sans-serif',
      abc: 'ABC Diatype Semi Mono, apple-system, sans-serif',
      abcHeading: 'ABC Diatype, apple-system, sans-serif',
      times: 'Times New Roman, Georgia, Garamond',
    },
    buttonBGColor: '#039BE5',
    buttonColor: '#FFF',
    contact: 'contact@tokentraxx.com',
    footerUsefulLinks: [],
    footerSocialURLs: [],
    homeLink: 'https://tokentraxx.com/studio/my-sahel',
    height: '35px',
  },
  'sample-tools-by-cr2': {
    title: 'Sample Tools by Cr2',
    navbarBg: '#000',
    backgroundThemeColor: '#039BE5',
    textColor: '#FFF',
    logo: 'https://traxxbucket-prod.s3.amazonaws.com/userprofile/ST+-+Thumbnail+Profile+Header-1.jpeg',
    fonts: {
      altone: 'Altone, apple-system, sans-serif',
      abc: 'ABC Diatype Semi Mono, apple-system, sans-serif',
      abcHeading: 'ABC Diatype, apple-system, sans-serif',
      times: 'Times New Roman, Georgia, Garamond',
    },
    buttonBGColor: '#039BE5',
    buttonColor: '#FFF',
    contact: 'contact@tokentraxx.com',
    footerUsefulLinks: [
      {
        title: 'Terms of Use',
        url: 'https://hs.tokentraxx.com/sample-tools-by-cr2-termsconditions',
      },
    ],
    footerSocialURLs: [],
    homeLink: 'https://sampletoolsbycr2.com/',
    height: '55px',
  },
  'npayme-marketplace': {
    title: 'Npayme Marketplace',
    navbarBg: '#FFF',
    backgroundThemeColor: '#f8f8f8',
    textColor: '#333333',
    logo: 'https://www.datocms-assets.com/73624/1709808492-npayme-removebg-preview.png',
    fonts: {
      altone: 'Altone, apple-system, sans-serif',
      abc: 'ABC Diatype Semi Mono, apple-system, sans-serif',
      abcHeading: 'ABC Diatype, apple-system, sans-serif',
      times: 'Times New Roman, Georgia, Garamond',
    },
    buttonBGColor: '#6743B4',
    buttonColor: '#FFF',
    contact: 'npayme@oneiro.io',
    footerUsefulLinks: [
      {
        title: 'npayme network',
        url: 'https://npayme.com/npayme-network/',
      },
      {
        title: 'Fundraising',
        url: 'https://npayme.com/fundraising/',
      },
      {
        title: 'Creators',
        url: 'https://npayme.com/creators/',
      },
      {
        title: 'DAO',
        url: 'https://npayme.com/affiliates/',
      },
      {
        title: 'Affiliates',
        url: 'https://npayme-dao.org/',
      },
    ],
    footerSocialURLs: [],
    homeLink: 'https://tokentraxx.com/studio/npayme-marketplace',
    height: '55px',
  },
  'elle-nft-collection': {
    title: 'Elle NFT Collection',
    navbarBg: '#000',
    backgroundThemeColor: '#039BE5',
    textColor: '#FFF',
    logo: 'https://traxxbucket-prod.s3.amazonaws.com/userprofile/elle-1717078789294.jpg',
    fonts: {
      altone: 'Altone, apple-system, sans-serif',
      abc: 'ABC Diatype Semi Mono, apple-system, sans-serif',
      abcHeading: 'ABC Diatype, apple-system, sans-serif',
      times: 'Times New Roman, Georgia, Garamond',
    },
    buttonBGColor: '#83A7A5',
    buttonColor: '#000',
    contact: 'contact@tokentraxx.com',
    footerUsefulLinks: [],
    footerSocialURLs: [],
    homeLink: 'https://tokentraxx.com/studio/elle-nft-collection',
    height: '55px',
  },
  'goodwood-fractionalised-horse-ownership': {
    title: 'Goodwood',
    navbarBg: '#000', // Navbar background color
    backgroundThemeColor: '#FFFFFF', // Set white background
    textColor: '#131313', // Default text color (black)
    logo: 'https://www.datocms-assets.com/73624/1692186826-4-logo.png',
    fonts: {
      altone: 'Altone, apple-system, sans-serif',
      abc: 'ABC Diatype Semi Mono, apple-system, sans-serif',
      abcHeading: 'ABC Diatype, apple-system, sans-serif',
      times: 'Times New Roman, Georgia, Garamond', // Serif font
    },
    buttonBGColor: 'transparent', // Button background remains transparent
    buttonColor: '#131313', // Black text
    contact: 'contact@tokentraxx.com',
    footerUsefulLinks: [],
    footerSocialURLs: [],
    homeLink: 'https://www.goodwood.com',
    height: '35px',
  },
};

export const STUDIOS_PAGES = [
  '/studio/[slug]',
  '/studio/nft/[slug]/[id]',
  '/studio/artists/[slug]/[artistslug]',
  '/studio-new/[slug]',
  '/studio-new/nft/[slug]/[id]',
  '/studio-new/artists/[slug]/[artistslug]',
  '/studio/artists/[slug]/perks/[perkSlug]',
];

// rarity percentage
export const HEART_PERCENT = {
  Asteroid: '2.51',
  'Blue Cracked': '4.22',
  'Orange Cracked': '0',
  'Green Cracked': '4.82',
  'Red Cracked': '3.63',
  'Shiny Marble': '1.19',
  'Disco Armour': '0.23',
  Planet: '0.56',
  'Green Liquid': '3.66',
  'Magenta Liquid': '3.30',
  'Purple Liquid': '2.94',
  'Rough Blue': '1.25',
  'Rough Red': '1.32',
  'Green Lazer': '0.26',
  'Warm Lazer': '0.50',
  'Purple Lazer': '0.40',
  Gold: '0.59',
  'Green Chrome': '4.29',
  'Red Chrome': '4.03',
  Iridescent: '4.82',
  'Blue Fade': '6.44',
  'Magenta Fade': '6.60',
  'Purple Fade': '7.03',
  'Dull Chrome': '2.28',
  'Dull Gold': '2.08',
  'Magenta Diamond': '0.50',
  'Red Diamond': '0.73',
  'Green Dimaond': '0.66',
  'Purple Diamond': '0.56',
  'Blue Diamond': '0',
  Crystal: '0.26',
  Quartz: '0.17',
  Volcanic: '0.26',
  Lava: '1.09',
  Marble: '0.76',
  Rock: '0.10',
  'Brushed Silver': '0',
  'Brushed Gold': '0',
  'Green Plastic': '8.28',
  'Orange Plastic': '0',
  'Pink Plastic': '0',
  'Blue Plastic': '9.01',
  'Red Plastic': '8.68',
};

export const ACCESSORY_PERCENT = {
  'Gold Flowers': '3.07',
  'Chrome Flowers': '3.27',
  'White Flowers': '3.23',
  'Chrome Snake': '3.23',
  'Lazer Ring': '0.20',
  'Lazer Snake': '2.90',
  'Gold Snake': '2.77',
  'Iridescent Snake': '2.71',
  'Lazer Spine': '6.93',
  'Iridescent Spine': '6.37',
  'Purple Spine': '7.13',
  'Gold Spine': '6.67',
  'Magenta Rope': '1.45',
  'Chome Rope': '1.12',
  'Gold Rope': '1.52',
  'Red Flowers': '3.20',
  'Blue Flowers': '3.30',
  'Chrome Spine': '6.04',
  'Bone Spine': '4.98',
  'Tinted Barbed Wire': '3.17',
  'Chrome Barbed Wire': '3.23',
  'Purple Barbed Wire': '2.97',
  'Lazer Chain': '0.30',
  'Gold Chain': '0.36',
  'Purple Chain': '0.30',
  'Gold Razor Wire': '3.86',
  'Lazer Razor Wire': '4.03',
  Bullets: '6.37',
  'Red Guitar Lead': '1.68',
  'Purple Spike Chain': '0.33',
  'Gold Spike Chain': '0.63',
  'Chrome Spike Chain': '0.73',
  'Gold Guitar Lead': '1.95',
};

export const ACCENT_PERCENT = {
  'Chrome Pill': '0.00',
  'Gold Pill': '3.60',
  'Red Pill': '2.84',
  'White & Gold Pill': '2.94',
  'Chrome Flower': '0.79',
  'Gold Flower': '0.36',
  'Chrome Lazer Flower': '0.83',
  'Lazer Flower': '0.59',
  'Lazer Pistol Bullet': '1.82',
  'Iridescent Bullet': '0.53',
  'Lazer Lines': '0.73',
  'Chrome Lines': '0.43',
  'Gold Lines': '0.73',
  'Red Mushroom': '3.43',
  'Gold Mushroom': '3.47',
  'Chrome Stick': '1.45',
  'Wood Stick': '1.12',
  'Gold Stick': '1.29',
  'Iridescent Crystal': '3.37',
  'Lazer Crystal': '3.30',
  'Green Crystal': '3.33',
  'Shattered Purple Crystal': '3.14',
  'Pink Rose': '0.43',
  'Red Rose': '0.50',
  'Chrome Chain': '0.53',
  'Gold Chain': '0.92',
  'Purple Chain': '0.76',
  'Purple Robot': '7.39',
  'White Robot': '6.60',
  'Gold Pistol bullet': '2.08',
  'Satin Pistol Bullet': '1.75',
  'Marble Pistol Bullet': '1.98',
  'Grey Pistol Bullet': '0.00',
  'Satin Rifle Bullet': '3.83',
  'Chrome Rifle Bullet': '4.26',
  'Gold Rifle Bullet': '3.96',
  'Grey Rifle Bullet': '0.00',
  'Red Cartridge': '0.69',
  'Shattered Pink Bulb': '4.59',
  'Yellow Bulb': '5.12',
  'Pink Orb': '0.33',
  'Shattered Purple Lines': '0.56',
  'Chrome Bone': '6.77',
  'Gold Bone': '6.73',
  'Purple Gem': '0.17',
};

export const DRUMS_PERCENT = {
  Graffiti6: '24.85',
  'Calyx & TeeBee': '20.79',
  'Oden & Fatzo': '14.32',
  'Flux Pavilion': '5.15',
  'Paul Woolford': '34.88',
};

export const BASS_PERCENT = {
  Graffiti6: '25.18',
  'Calyx & TeeBee': '35.41',
  'Oden & Fatzo': '5.05',
  'Flux Pavilion': '18.88',
  'Paul Woolford': '15.48',
};

export const KEYS_PERCENT = {
  Graffiti6: '10.66',
  'Calyx & TeeBee': '29.54',
  'Oden & Fatzo': '38.35',
  'Flux Pavilion': '6.01',
  'Paul Woolford': '15.45',
};

export const INSTRUMENT_1_PERCENT = {
  Graffiti6: '5.71',
  'Calyx & TeeBee': '25.12',
  'Oden & Fatzo': '34.88',
  'Flux Pavilion': '15.08',
  'Paul Woolford': '19.21',
};

export const FEATURE_PERCENT = {
  Graffiti6: '24.69',
  'Calyx & TeeBee': '33.60',
  'Oden & Fatzo': '4.88',
  'Flux Pavilion': '16.60',
  'Paul Woolford': '20.23',
};

export const INSTRUMENT_2_PERCENT = {
  Graffiti6: '29.08',
  'Calyx & TeeBee': '4.75',
  'Oden & Fatzo': '20.53',
  'Flux Pavilion': '10.66',
  'Paul Woolford': '34.98',
};

export const FX_PERCENT = {
  Graffiti6: '27.82',
  'Calyx & TeeBee': '10.73',
  'Oden & Fatzo': '16.04',
  'Flux Pavilion': '40.50',
  'Paul Woolford': '4.92',
};

export const LEAD_VOCAL_PERCENT = {
  Graffiti6: '5.25',
  'Calyx & TeeBee': '19.41',
  'Oden & Fatzo': '14.46',
  'Flux Pavilion': '25.21',
  'Paul Woolford': '35.68',
};

export const NO_HEADER = ['/project-options', '/loading'];

export const VENLY_NETWORK = {
  polygon: 'MATIC',
  ethereum: 'ETHEREUM',
};

export const WEBFLOW_PAGES = [''];

export const traxxStemzSlug = 'revolution';

export const joshSavageSlug = 'joshsavage';

export const emilGSlug = 'emil-g-music';

export const CMS_EMILG_SLUG = 'emilg';
export const CMS_STEMZ_SLUG = 'graffiti6';

export const SARA_NFT = [
  'sonic-starshifters-vaedar-pass-holders',
  'resonant-savants-vaedar-pass-holders',
  'sonic-starshifters',
  'resonant-savants',
];

export const SARA_DROP = {
  standardWhiteList: 'sonic-starshifters-vaedar-pass-holders',
  premiumWhiteList: 'resonant-savants-vaedar-pass-holders',
  standardPublic: 'sonic-starshifters',
  premiumPublic: 'resonant-savants',
};

export const withPaperContractInfo = {
  ethereum: {
    native: '830f5378-4d09-494a-85e8-ba014e085c37',
    erc20: '049655ca-d612-4749-b640-4ea99d0a83b2',
    josh: '',
  },
  polygon: {
    native: '645a825c-fc38-461c-9bef-83c52735ced1',
    erc20: 'c1d5cef7-3cbe-4c82-a6ba-e208108a1054',
    josh: 'b732dae4-d0bf-48a4-acb1-bae9dced0c95',
  },
};

export const EMAIL_MODAL_NFT_SLUG = [
  'world-traveller',
  'esi-13',
  'akwasi-22',
  'yao-13',
  'kobi-13',
  'awan-55',
  'kwafica-55',
  'abeiku-55',
  'jojo-22',
  'ato-22',
  'yawa-07',
  'akusea-07',
  'araba-07',
  // 'the-rising-tide',
  'keep-tryin',
  'save-me',
  'i-wouldnt-be-me',
  'silver-membership',
  'gold-membership',
  'platinum-membership',
  'ep1-ryan-edition',
  'ep1-tommy-edition',
  'ep1-pedro-edition',
  'ep1-zach-edition',
  'ep1-james-edition',
  'ep1-bundle',
  'plan-zero-records-crank-it-up',
  'vip-film-pass',
  'lofi-sample-pack',
  'matt-sassari-x-sample-tools-by-cr2-vol-1-regular',
  'matt-sassari-x-sample-tools-by-cr2-vol-1-gold',
  'matt-sassari-x-sample-tools-by-cr2-vol-1-platinum',
];

export const emailMetaData = (slug: string) => {
  slug = slug.includes('world-traveller') ? 'world-traveller' : slug;
  switch (slug) {
    case 'world-traveller':
      return {
        title: 'Your Emil G Music NFT benefits',
        element: (
          <>
            <Typography textStyle="s" color="textDefault">
              Claim your benefits by entering your email when purchasing this
              digital collectible, benefits with be delivered to you by the
              artist following your purchase.
            </Typography>
            <Box pt="three" />
          </>
        ),
      };
    case 'esi-13':
    case 'akwasi-22':
    case 'yao-13':
    case 'kobi-13':
    case 'awan-55':
    case 'kwafica-55':
    case 'abeiku-55':
    case 'jojo-22':
    case 'ato-22':
    case 'yawa-07':
    case 'akusea-07':
    case 'araba-07':
      return {
        title: 'Your Kwame Kwaten NFT benefits',
        element: (
          <>
            <Typography textStyle="s" color="textDefault">
              Buy 1 - Join Kwame&apos;s Music Business Insights session + early
              access to future sessions.
            </Typography>
            <Box pt="three" />
            <Typography textStyle="s" color="textDefault">
              Buy 3 - Contribute to the live discussion + access to recorded
              Insights sessions.
            </Typography>
            <Box pt="three" />
            <Typography textStyle="s" color="textDefault">
              Buy 6 - Above benefits + review of your music/project in the
              session. One project under review will be chosen for the
              &apos;Golden Ticket&apos; (1 hour one-to-one session with Kwame).
              Plus 60 TRAXX to supplied Polygon wallet.
            </Typography>
          </>
        ),
      };
    // case 'the-rising-tide':
    case 'keep-tryin':
      return {
        title: 'Your Keep Tryin’ Benefits',
        element: (
          <>
            <Typography textStyle="s" color="textDefault">
              You&apos;ve successfully registered for the Keep Tryin&apos;
              benefits.
            </Typography>
            <Box pt="five" />
            <Box>
              <Typography textStyle="s" color="textLow">
                Questions?
              </Typography>
            </Box>
            <Box flexDirection="row" flexWrap="wrap">
              <Typography textStyle="s" color="textLow">
                Get in touch via&nbsp;
              </Typography>
              <UnstyledHtmlAnchor
                href="https://t.me/TokenTraxxMusic"
                target="blank"
              >
                <Typography
                  textStyle="s"
                  color="textLow"
                  textDecoration="underline"
                  css={{textUnderlineOffset: '2px'}}
                >
                  telegram
                </Typography>
              </UnstyledHtmlAnchor>
              <Typography textStyle="s" color="textLow">
                &nbsp;or at&nbsp;
              </Typography>
              <UnstyledHtmlAnchor
                href="mailto:marketing@tokentraxx.com"
                target="blank"
              >
                <Typography
                  textStyle="s"
                  color="textLow"
                  textDecoration="underline"
                  css={{textUnderlineOffset: '2px'}}
                >
                  marketing@tokentraxx.com
                </Typography>
              </UnstyledHtmlAnchor>
            </Box>
          </>
        ),
      };
    case 'save-me':
      return {
        title: 'Your Save Me by Contxt confirmation',
        element: (
          <>
            <Typography textStyle="s" color="textDefault">
              Buy 1 - Join Contxt&apos;s Music Business Insights session + early
              access to future sessions.
            </Typography>
            <Box pt="three" />
            <Typography textStyle="s" color="textDefault">
              Buy 3 - Contribute to the live discussion + access to recorded
              Insights sessions.
            </Typography>
          </>
        ),
      };
    case 'i-wouldnt-be-me':
      return {
        title: 'Your Kirk Desoto Music NFT benefits',
        element: (
          <>
            <Typography textStyle="s" color="textDefault">
              Please provide your email, this helps the artist to keep in
              contact regarding your benefits following your purchase.
            </Typography>
            <Box pt="three" />
          </>
        ),
      };
    case 'silver-membership':
    case 'gold-membership':
    case 'platinum-membership':
      return {
        title: `Your  ${capitalize(slug.replace('membership', ''))} Membership`,
        element: (
          <>
            <>
              <Typography textStyle="s" color="textDefault">
                You&apos;ve successfully registered for the $
                {capitalize(slug.replace('membership', ''))} Membership Tier.
                Your full benefits will be activated within the next week.
              </Typography>
              <Box pt="five" />
              <Box>
                <Typography textStyle="s" color="textLow">
                  Questions?
                </Typography>
              </Box>
              <Box flexDirection="row" flexWrap="wrap">
                <Typography textStyle="s" color="textLow">
                  Get in touch via&nbsp;
                </Typography>
                <UnstyledHtmlAnchor
                  href="https://t.me/TokenTraxxMusic"
                  target="blank"
                >
                  <Typography
                    textStyle="s"
                    color="textLow"
                    textDecoration="underline"
                    css={{textUnderlineOffset: '2px'}}
                  >
                    telegram
                  </Typography>
                </UnstyledHtmlAnchor>
                <Typography textStyle="s" color="textLow">
                  &nbsp;or at&nbsp;
                </Typography>
                <UnstyledHtmlAnchor
                  href="mailto:membership@tokentraxx.com"
                  target="blank"
                >
                  <Typography
                    textStyle="s"
                    color="textLow"
                    textDecoration="underline"
                    css={{textUnderlineOffset: '2px'}}
                  >
                    membership@tokentraxx.com
                  </Typography>
                </UnstyledHtmlAnchor>
              </Box>
            </>
          </>
        ),
      };
    case 'ep1-ryan-edition':
    case 'ep1-tommy-edition':
    case 'ep1-pedro-edition':
    case 'ep1-zach-edition':
    case 'ep1-james-edition':
    case 'ep1-bundle':
      return {
        title: 'Your HAT Music NFT benefits',
        element: (
          <>
            <Typography textStyle="s" color="textDefault">
              Claim your benefits by entering your email when purchasing this
              digital collectible, benefits with be delivered to you by the
              artist following your purchase.
            </Typography>
            <Box pt="three" />
          </>
        ),
      };
    case 'plan-zero-records-crank-it-up':
      return {
        title: 'Your Genzo NFT benefits',
        element: (
          <>
            <Typography textStyle="s" color="textDefault">
              Please enter your email address / X handle so you can receive any
              upcoming utility or rewards for being a holder
            </Typography>
            <Box pt="three" />
          </>
        ),
      };
    case 'vip-film-pass':
      return {
        title: 'Your VIP Film Pass NFT benefits',
        element: (
          <>
            <Typography textStyle="s" color="textDefault">
              Please enter your email address / X handle so you can receive any
              upcoming utility or rewards for being a holder
            </Typography>
            <Box pt="three" />
          </>
        ),
      };
    case 'lofi-sample-pack':
      return {
        title: 'Your Lofi Sample Pack NFT benefits',
        element: (
          <>
            <Typography textStyle="s" color="textDefault">
              Please enter your email address / X handle so you can receive any
              upcoming utility or rewards for being a holder
            </Typography>
            <Box pt="three" />
          </>
        ),
      };
    case 'matt-sassari-x-sample-tools-by-cr2-vol-1-regular':
    case 'matt-sassari-x-sample-tools-by-cr2-vol-1-gold':
    case 'matt-sassari-x-sample-tools-by-cr2-vol-1-platinum':
      return {
        title: 'Your CR2 NFT benefits',
        element: (
          <>
            <Typography textStyle="s" color="textDefault">
              Please enter your email address / X handle so you can receive any
              upcoming utility or rewards for being a holder
            </Typography>
            <Box pt="three" />
          </>
        ),
      };
    default:
      return {
        title: '',
        element: <></>,
      };
  }
};

export const JOSH_WHITELISTED_USERS = [
  {
    address: '0xe0d41d49dD5fFA74B09feB82531AFdf84bdA632E',
    maxQuantity: 335,
  },
  {
    address: '0xB54c819Fc22AA4607e7704C41e4D445Aef8Efc60',
    maxQuantity: 0,
  },
];

/**
 * Discount for selected drop
 */
// TODO: Update the structure in more generic way.
export const CREATOR_UNIQUE_REF = {
  'kena-rose': 'kena-rose',
};

interface DropDiscountInfo {
  [x: string]: {
    whitelist: string[];
  };
}

export const DROP_DISCOUNT_INFO: DropDiscountInfo = {
  [CREATOR_UNIQUE_REF['kena-rose']]: {
    whitelist: [
      '0x52fD7b63B16AC94cEd97838C25E2660dD6b0E0D1',
      '0xC17C673D9aFba81606f6394808d75309abD40EF9',
      '0x3cd378C9B1cB5f147eBF1b2c2564118946AE4ba1',
      '0x1090a30864234e32655EA9694a511cCBE6734FC6',
      '0x07f67984844248542FdACC84A95ce24BF97513b9',
      '0x5aE92B69725FaB29524ee91FF5127A7159BF9d95',
      '0x38f55F77cE4087e1C3fBF4873FEC69F2A2C2037E',
      '0x202FF78c06536a8AC03ab7A46623010333E774Ca',
      '0x879b2e79a001bFd1288e91cc9b2E43442f0fda75',
      '0x7b753919B953b1021A33F55671716Dc13c1eAe08',
      '0x5F36B2AD2C0CcaFb2c46ad689b277b110724b3D5',
      '0x0C2Caa6fC70FFe95De67554f3f8859bfd8ac4Be0',
      '0xB54c819Fc22AA4607e7704C41e4D445Aef8Efc60',
      '0xe723628780EbD00f063cC9235DE4C4C967d473DD',
    ],
  },
};
