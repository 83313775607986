import React, {useEffect} from 'react';
import {useRouter} from 'next/router';
import {PurchaseHistoryLog} from '@ttx/design-system';
import {useAuthenticationContext} from '../../../contexts/authentication-context';
import {formatAddress} from '../utils';
import {RightSideDrawerModal} from '../right-side-drawer-modal';
import {useTtxQuery} from '../../../hooks/ttx-trpc';
import {Transaction} from '../../../server/router/user/types';

interface PurchaseHistoryProps {
  isModalMounted: boolean;
  isModalShowing: boolean;
  onClosePress: () => void;
  onDrawerCloseAnimationFinish: () => void;
}

export const PurchaseHistory: React.FC<PurchaseHistoryProps> = ({
  isModalMounted,
  isModalShowing,
  onClosePress,
  onDrawerCloseAnimationFinish,
}) => {
  const router = useRouter();

  const {walletAddress, isAuthenticated} = useAuthenticationContext();

  const {data, refetch, isLoading, isRefetching} = useTtxQuery(
    [
      'user.get-transaction-history', {
        limit: 10,
        page: 1,
        paymentId: undefined,
      },
    ],
    {enabled: !!isAuthenticated()},
  );

  const onTryAgain = (slug: string) => {
    router.push(`/tracks/${slug}`);
    onClosePress();
  };

  if (!isModalMounted) return null;

  return (
    <RightSideDrawerModal
      isModalShowing={isModalShowing}
      onClosePress={onClosePress}
      onDrawerCloseAnimationFinish={onDrawerCloseAnimationFinish}
    >
      <PurchaseHistoryLog
        onRefreshPurchaseHistory={refetch}
        onClose={onClosePress}
        onTryAgain={onTryAgain}
        formattedWalletAddress={
          walletAddress ? formatAddress(walletAddress) : null
        }
        purchaseHistoryData={(data as Transaction[]) || undefined}
        loading={isLoading || isRefetching}
      />
    </RightSideDrawerModal>
  );
};
