import {
  // VisibilityBox,
  WalletConnectIcon,
  Typography,
  TransparentButton,
  Box,
  MetaMaskIcon,
  HorizontalDivider,
  ZilliqaIcon,
  // Button,
  EmaiLoginIcon,
  ArrowDownIcon,
  Pressable,
  MoreIcon,
  // WithpaperIcon,
} from '@ttx/design-system';
import {usePlausible} from 'next-plausible';
import React, {useCallback, useState} from 'react';
import {useAuthenticationContext} from '../../../contexts/authentication-context';
import {WalletRegistrationSource} from '../../../contexts/authentication-context/types';

interface WalletConnectionConfig {
  displayName: string;
  providerName: WalletRegistrationSource;
  icon: React.ReactElement;
  subField?: WalletConnectionConfig[];
  order: number;
}

const walletConnectionConfigs: WalletConnectionConfig[] = [
  {
    displayName: 'MetaMask',
    providerName: 'metaMask',
    icon: <MetaMaskIcon color={'var(--studio-primaryText)' as any} size={18} />,
    order: 1,
  },
  {
    displayName: 'Email login (Venly)',
    providerName: 'venly',
    icon: (
      <EmaiLoginIcon color={'var(--studio-primaryText)' as any} size={18} />
    ),
    order: 2,
  },
  {
    displayName: 'Other',
    providerName: 'walletConnect',
    icon: <MoreIcon color={'var(--studio-primaryText)' as any} size={18} />,
    subField: [
      {
        displayName: 'Wallet Connect',
        providerName: 'walletConnect',
        icon: (
          <WalletConnectIcon
            color={'var(--studio-primaryText)' as any}
            size={18}
          />
        ),
        order: 5,
      },
      {
        displayName: 'ZilPay',
        providerName: 'zilPay',
        icon: (
          <ZilliqaIcon color={'var(--studio-primaryText)' as any} size={18} />
        ),
        order: 6,
      },
    ],
    order: 4,
  },
];

type Network = 'ETHEREUM' | 'MATIC';

// eslint-disable-next-line no-shadow
export enum PaymentType {
  Cash = 'cash',
  Crypto = 'crypto',
}
export interface WalletConnectionButtonGroupProps {
  children?: never;
  /**
   * The wallet provider options to present to the user.
   * If not passed, the options will fallback to a default value.
   */
  options?: WalletRegistrationSource[];
  network?: Network;
  paymentType?: PaymentType;
}

const DEFAULT_OPTIONS: WalletRegistrationSource[] = [
  'walletConnect',
  'zilPay',
  'metaMask',
  'venly',
  'paper',
]; // TODO: support "magicLinkEmail", "magicLinkSMS" in future

/**
 *
 * @param recommended string
 * @param provider string
 * @param order number
 * @returns number
 * this method will return number based on user selected paymentType(Cash | Crypto)
 */
const updateOrder = (
  recommended: string,
  provider: string,
  order: number,
): number => {
  switch (provider) {
    case 'venly':
      return recommended === 'Email login (Venly)' ? 1 : 2;
    case 'metaMask':
      return recommended === 'MetaMask' ? 1 : 2;
    default:
      return order;
  }
};

export const WalletConnectionButtonGroup: React.FC<
  WalletConnectionButtonGroupProps
> = ({
  options = DEFAULT_OPTIONS,
  network,
  paymentType = PaymentType.Cash,
}: WalletConnectionButtonGroupProps) => {
  const authenticationContext = useAuthenticationContext();
  const {walletConnected, walletProvider, logout, connectWallet} =
    authenticationContext;

  const [connectingProvider, setConnectingProvider] =
    useState<WalletRegistrationSource | null>(null);

  const [expendOther, setExpendOther] = useState<boolean>(false);
  const plausible = usePlausible();

  const handlePress = useCallback(
    async (providerName: WalletRegistrationSource) => {
      if (providerName === 'paper') {
        plausible('Email Login Selected');
      }
      if (providerName === 'venly') {
        plausible('Email Login Selected');
      }
      if (providerName === 'metaMask') {
        plausible('Metamask Login Selected');
      }
      if (walletConnected) {
        logout();
      } else {
        try {
          setConnectingProvider(providerName);
          await connectWallet(providerName, network);
        } finally {
          setConnectingProvider(null);
        }
      }
      return () => {
        setConnectingProvider(null);
      };
    },
    [connectWallet, logout, network, plausible, walletConnected],
  );

  const recommended =
    paymentType === PaymentType.Crypto ? 'MetaMask' : 'Login with Paper';

  const filteredConfigs: WalletConnectionConfig[] = walletConnectionConfigs
    .filter(({providerName}) => options.includes(providerName))
    .map((value) => ({
      ...value,
      order: updateOrder(recommended, value.providerName, value.order),
    }))
    .sort((a, b) => a.order - b.order);

  return (
    <>
      {filteredConfigs.map(
        ({providerName, icon, displayName, subField}, idx) => {
          const isLast: boolean = idx === filteredConfigs.length - 1;

          const getLabel = () => {
            if (walletProvider === providerName && !connectingProvider) {
              return `${walletConnected ? 'Dis' : 'Re'}connect ${displayName}`;
            }
            if (connectingProvider === providerName) {
              return 'Connecting...';
            }
            return `${displayName}`;
          };

          const isOther = displayName === 'Other';

          return (
            <>
              <Box key={providerName}>
                <Pressable
                  onPress={() => {
                    if (isOther) {
                      setExpendOther(!expendOther);
                    }
                  }}
                >
                  <Box flexDirection="row" alignItems="center" p="lg">
                    <Box pr={['ten', 'fifteen', 'twenty']}>{icon}</Box>
                    <TransparentButton
                      onPress={() =>
                        isOther
                          ? setExpendOther(!expendOther)
                          : handlePress(providerName)
                      }
                      disabled={!!connectingProvider}
                    >
                      <Typography
                        css={{
                          color: 'var(--studio-primaryText)',
                        }}
                        textDecoration="underline"
                        textStyle="s"
                      >
                        {getLabel()}
                      </Typography>
                    </TransparentButton>
                    {displayName === recommended && (
                      <Box
                        backgroundColor="surfaceLight"
                        style={{
                          padding: '0px 8px',
                          margin: '6px',
                          borderRadius: 18,
                        }}
                        borderRadius="two"
                      >
                        <Typography
                          color="textDefault"
                          fontSize="xs"
                          fontFamily="times"
                        >
                          Recommended
                        </Typography>
                      </Box>
                    )}
                    <Box />
                    {isOther && (
                      <Box
                        style={{position: 'absolute', right: '0px'}}
                        pr={['ten', 'fifteen', 'twenty']}
                      >
                        <ArrowDownIcon />
                      </Box>
                    )}
                  </Box>
                </Pressable>
                {!isLast && <HorizontalDivider />}
              </Box>

              {expendOther && subField && (
                <>
                  {subField.map((item, i) => (
                    <Box key={item.providerName}>
                      <HorizontalDivider />
                      <Box flexDirection="row" alignItems="center" p="lg">
                        <Box pr={['ten', 'fifteen', 'twenty']}>{item.icon}</Box>

                        <TransparentButton
                          onPress={() => handlePress(item.providerName)}
                          disabled={!!connectingProvider}
                        >
                          <Typography
                            css={{
                              color: 'var(--studio-primaryText)',
                            }}
                            textDecoration="underline"
                            textStyle="s"
                          >
                            {item.displayName}
                          </Typography>
                        </TransparentButton>
                      </Box>
                      {i === filteredConfigs.length - 1 && (
                        <HorizontalDivider />
                      )}
                    </Box>
                  ))}
                </>
              )}
            </>
          );
        },
      )}
    </>
  );
};
