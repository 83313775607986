/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  ArrowRightIcon,
  Button,
  CurrencyIcon,
  FrameworkImage,
  HorizontalDivider,
} from '../..';
import {Box} from '../../box';
import {Typography} from '../typography';
import {useBreakpoint} from '../../use-breakpoint';
import {CollectionImage} from './collection-image';
import {extractLink} from '../../artist-collection/utils';

export interface ArtistCollectioneItemProps {
  onlyImage?: boolean;
  imageSrc: string;
  title: string;
  network?: string;
  description: string;
  collectionId?: string;
  nftCount?: number | null;
  isLeft?: boolean;
  isMobile: boolean;
  artistName: string | null | undefined;
  artistImg: string | undefined;
  buttonText?: string;
  onExplore?: () => void;
}

export const ArtistCollectioneItem: React.FC<ArtistCollectioneItemProps> = ({
  buttonText,
  onlyImage,
  imageSrc,
  title,
  network,
  description,
  collectionId,
  nftCount,
  isLeft,
  artistName,
  artistImg,
  onExplore,
}: ArtistCollectioneItemProps) => {
  const {currentBreakpoint} = useBreakpoint();
  const isMobile = window.innerWidth <= 400;
  if (isMobile) {
    return (
      <Box
        width="100%"
        borderTopWidth="one"
        borderTopStyle="solid"
        padding="four"
        paddingTop="eight"
        marginBottom="eight"
        alignItems="center"
        css={{
          borderTopColor: 'var(--studio-grey32)',
        }}
      >
        <Box
          width="100%"
          height="348px"
          marginTop="eight"
          style={{paddingLeft: '5px', marginLeft: '-18px'}}
        >
          <CollectionImage
            onlyImage={!!onlyImage}
            isLeft
            imageSrc={imageSrc}
            nftCount={nftCount}
            isMobile={isMobile}
          />
        </Box>
        <Box width="100%" flex={1} marginTop="eight">
          <Box flexDirection="row">
            <Typography
              fontSize="h2"
              css={{color: 'var(--studio-textDefault)'}}
            >
              {title}
            </Typography>
            <Box right="0px" top="20px" position="absolute">
              <CurrencyIcon
                name="MATIC"
                size={18}
                css={{color: 'var(--studio-greyPrimary)'}}
              />
            </Box>
          </Box>
          <Box flexDirection="row" marginTop="two" marginBottom="four">
            <Box
              height={18}
              width={18}
              borderRadius="circle"
              borderStyle="solid"
              borderWidth="one"
              overflow="hidden"
              css={{borderColor: 'var(--studio-outlines)'}}
            >
              {artistImg && (
                <FrameworkImage
                  layout="fill"
                  objectFit="cover"
                  src={artistImg}
                />
              )}
            </Box>
            &nbsp;&nbsp;
            <Typography css={{color: 'var(--studio-textDefault)'}}>
              {artistName}
            </Typography>
          </Box>
          <HorizontalDivider css={{color: 'var(--studio-grey12)'}} h="0.5px" />
          <Box marginTop="four">
            <Typography
              css={{color: 'var(--studio-textDefault)'}}
              fontFamily="abc"
              fontSize="s"
            >
              {description}
            </Typography>
          </Box>
          <Box marginTop="twelve" width="100%">
            {extractLink(description) ? (
              <a
                href={extractLink(description) || ''}
                style={{textDecoration: 'none'}}
              >
                <Button
                  type="primary"
                  size="M"
                  leftAccessory={
                    <ArrowRightIcon customColor="var(--studio-brandPrimaryText)" />
                  }
                  buttonStyle={{
                    width: '320px',
                    maxWidth: '100%',
                    backgroundColor: 'var(--studio-brandPrimary)',
                    textColor: 'var(--studio-brandPrimaryText)',
                  }}
                  hasButtonStyle
                >
                  Donate
                </Button>
              </a>
            ) : (
              <Button
                type="primary"
                leftAccessory={
                  <ArrowRightIcon customColor="var(--studio-brandPrimaryText)" />
                }
                buttonStyle={{
                  width: '100%',
                  backgroundColor: 'var(--studio-brandPrimary)',
                  textColor: 'var(--studio-brandPrimarText)',
                }}
                onPress={onExplore}
              >
                {buttonText || 'Explore the collection'}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  const is1200px =
    currentBreakpoint && ['1440px', '1200px'].includes(currentBreakpoint);
  const is960px = currentBreakpoint && currentBreakpoint === '960px';
  const is768px = currentBreakpoint && currentBreakpoint === '768px';

  const textContainerWidth = !is1200px
    ? '50%'
    : isLeft
    ? 'calc(100% - 620px)'
    : 'calc(100% - 624px)';
  const imageConatinerWidth = is1200px ? '570px' : '50%';
  const heightWidth = is1200px ? {w: 480, h: 480} : {w: 346, h: 370};

  if (is768px) {
    return (
      <Box
        flexDirection="row"
        width="100%"
        maxWidth="2000px"
        minHeight={heightWidth.h}
        borderTopWidth="one"
        borderTopStyle="solid"
        paddingTop="eight"
        paddingBottom="eight"
        paddingLeft="three"
        paddingRight="three"
        css={{borderTopColor: 'var(--studio-grey32)'}}
      >
        <Box
          width={imageConatinerWidth}
          style={
            !is1200px
              ? {}
              : isLeft
              ? {paddingLeft: '30px'}
              : {paddingRight: '30px', marginLeft: '40px'}
          }
        >
          <CollectionImage
            onlyImage={!!onlyImage}
            is1200px={is1200px}
            is960px={is960px}
            is768px={is768px}
            isLeft
            imageSrc={imageSrc}
            nftCount={nftCount}
            isMobile={isMobile}
          />
        </Box>
        <Box
          style={{
            width: textContainerWidth,
            paddingLeft: '30px',
          }}
        >
          <Box flexDirection="row">
            <Box width="80%">
              <Typography
                fontSize="heroSmall"
                css={{color: 'var(--studio-textDefault)'}}
              >
                {title}
              </Typography>
            </Box>

            <Box right="30px" top="20px" position="absolute">
              <CurrencyIcon
                name="MATIC"
                size={18}
                css={{color: 'var(--studio-greyPrimary)'}}
              />
            </Box>
          </Box>
          <Box flexDirection="row" marginTop="two" marginBottom="four">
            <Box
              height={18}
              width={18}
              borderRadius="circle"
              borderStyle="solid"
              borderWidth="one"
              overflow="hidden"
              css={{borderColor: 'var(--studio-outlines)'}}
            >
              {artistImg && (
                <FrameworkImage
                  layout="fill"
                  objectFit="cover"
                  src={artistImg}
                />
              )}
            </Box>
            &nbsp;&nbsp;
            <Typography css={{color: 'var(--studio-textDefault)'}}>
              {artistName}
            </Typography>
          </Box>
          <HorizontalDivider css={{color: 'var(--studio-grey12)'}} />
          <Box marginTop="four">
            <Typography
              css={{color: 'var(--studio-textDefault)'}}
              fontFamily="abc"
            >
              {description}
            </Typography>
          </Box>
          <Box marginTop="five">
            {extractLink(description) ? (
              <a
                href={extractLink(description) || ''}
                style={{textDecoration: 'none'}}
              >
                <Button
                  type="primary"
                  size="M"
                  leftAccessory={
                    <ArrowRightIcon customColor="var(--studio-brandPrimaryText)" />
                  }
                  buttonStyle={{
                    width: '320px',
                    maxWidth: '100%',
                    backgroundColor: 'var(--studio-brandPrimary)',
                    textColor: 'var(--studio-brandPrimaryText)',
                  }}
                  hasButtonStyle
                >
                  Donate
                </Button>
              </a>
            ) : (
              <Button
                type="primary"
                size="M"
                leftAccessory={
                  <ArrowRightIcon customColor="var(--studio-brandPrimaryText)" />
                }
                buttonStyle={{
                  width: '320px',
                  maxWidth: '100%',
                  backgroundColor: 'var(--studio-brandPrimary)',
                  textColor: 'var(--studio-brandPrimaryText)',
                }}
                onPress={onExplore}
              >
                {buttonText || 'Explore the collection'}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      flexDirection={isLeft ? 'row' : 'row-reverse'}
      width="100%"
      maxWidth="2000px"
      minHeight={heightWidth.h}
      borderTopWidth="one"
      borderTopStyle="solid"
      paddingTop="eight"
      paddingBottom="eight"
      paddingLeft="three"
      paddingRight="three"
      // css={{
      //   borderTopColor: 'var(--studio-grey32)',
      // }}
    >
      <Box
        width={imageConatinerWidth}
        style={
          !is1200px
            ? {}
            : isLeft
            ? {paddingLeft: '30px'}
            : {paddingRight: '30px', marginLeft: '40px'}
        }
      >
        <CollectionImage
          onlyImage={!!onlyImage}
          is1200px={is1200px}
          is960px={is960px}
          is768px={is768px}
          isLeft={isLeft}
          imageSrc={imageSrc}
          nftCount={nftCount}
          isMobile={isMobile}
          hideCollectionCount={!!extractLink(description)}
        />
      </Box>
      <Box
        style={{
          width: textContainerWidth,
        }}
      >
        <Box flexDirection="row">
          <Typography
            fontSize="heroSmall"
            style={{color: 'var(--studio-textDefault)'}}
          >
            {title}
          </Typography>
          <Box right={isLeft ? '30px' : '43px'} top="20px" position="absolute">
            <CurrencyIcon
              name="MATIC"
              size={18}
              css={{color: 'var(--studio-greyPrimary)'}}
            />
          </Box>
        </Box>
        <Box flexDirection="row" marginTop="two" marginBottom="four">
          <Box
            height={18}
            width={18}
            borderRadius="circle"
            borderStyle="solid"
            borderWidth="one"
            overflow="hidden"
            css={{borderColor: 'var(--studio-outlines)'}}
          >
            {artistImg && (
              <FrameworkImage layout="fill" objectFit="cover" src={artistImg} />
            )}
          </Box>
          &nbsp;&nbsp;
          <Typography css={{color: 'var(--studio-textDefault)'}}>
            {artistName}
          </Typography>
        </Box>
        <HorizontalDivider css={{backgroundColor: 'var(--studio-grey12)'}} />
        <Box marginTop="four">
          <Typography
            css={{color: 'var(--studio-textDefault)'}}
            fontFamily="abc"
          >
            {description.split('Link: ')[0]}
          </Typography>
        </Box>
        <Box marginTop="five">
          {extractLink(description) ? (
            <a
              href={extractLink(description) || ''}
              style={{textDecoration: 'none'}}
            >
              <Button
                type="primary"
                size="M"
                leftAccessory={
                  <ArrowRightIcon customColor="var(--studio-brandPrimaryText)" />
                }
                buttonStyle={{
                  width: '320px',
                  maxWidth: '100%',
                  backgroundColor: 'var(--studio-brandPrimary)',
                  textColor: 'var(--studio-brandPrimaryText)',
                }}
                hasButtonStyle
              >
                Donate
              </Button>
            </a>
          ) : (
            <Button
              type="primary"
              size="M"
              leftAccessory={
                <ArrowRightIcon customColor="var(--studio-brandPrimaryText)" />
              }
              buttonStyle={{
                width: '320px',
                maxWidth: '100%',
                backgroundColor: 'var(--studio-brandPrimary)',
                textColor: 'var(--studio-brandPrimaryText)',
              }}
              onPress={onExplore}
              hasButtonStyle
            >
              {buttonText || 'Explore the collection'}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
