/** @jsxImportSource react */
import {useRouter} from 'next/router';
import React, {useEffect, useState} from 'react';
import {
  DiscordIcon,
  InstagramIcon,
  TelegramIcon,
  TiktokIcon,
  TwitterIcon,
  YouTubeIcon,
  LinkedinIcon,
} from '@ttx/design-system';
import {STUDIOS_PAGES} from './consts';
import {useTtxQuery} from '../hooks/ttx-trpc';

export const studioProperties = () => {
  const router = useRouter();
  const {pathname, query} = router;
  const {slug = ''} = query;
  const isStudioPage = STUDIOS_PAGES.includes(pathname);

  return {isStudioPage, studioSlug: slug};
};

// For Global Used Params
export type Studiotype = {
  primaryButton: string;
  primaryButtonText: string;
  secondaryButton: string;
  secondaryButtonText: string;
  backgroundColor: string;
  primaryText: string;
  secondaryText: string;
  footerBackground: string;
  footerText: string;
  logoUrl: string;
  fontFamily?: string;
};

export class StudioUtility {
  public static studioTheme: Studiotype;
}

const generateCssClasses = ({
  primaryButton,
  primaryButtonText,
  secondaryButton,
  secondaryButtonText,
  backgroundColor,
  primaryText,
  secondaryText,
  footerBackground,
  footerText,
  logoUrl,
  fontFamily,
}: Studiotype) => {
  return `
        :root {
        --studio-primaryButton: ${primaryButton} !important;
        --studio-primaryButtonText: ${primaryButtonText} !important;
        --studio-secondaryButton: ${secondaryButton} !important;
        --studio-secondaryButtonText: ${secondaryButtonText} !important;
        --studio-backgroundColor: ${backgroundColor} !important;
        --ttx-colors-surfaceDark:${backgroundColor} !important;
        --studio-primaryText: ${primaryText} !important;
        --studio-secondaryText: ${secondaryText} !important;
        --studio-footerBackground: ${footerBackground} !important;
        --studio-footerText: ${footerText} !important;
        --studio-white-color: #fff !important;
        --studio-brandPrimary: ${primaryButton} !important;
        --studio-brandPrimaryText: ${primaryButtonText} !important;
        --studio-errorPrimary: '#E63280';
        --studio-warningPrimary: '#E74F00';
        --studio-surfaceDark: ${primaryText} !important;
        --studio-surfaceBlack: '#000';
        --studio-surfaceLight: '#303030';
        --studio-surfaceInvert: '#F9FAF2';
        --studio-outlines: '#FFF';
        --studio-outlinesInvert: '#010203';
        --studio-textDefault: ${primaryText} !important;
        --studio-textLow: ${secondaryButtonText} !important;
        --studio-textInvert: '#010203';
        --studio-gray0: ${primaryText} !important;
        --studio-grey12: #1F2021 !important;
        --studio-grey18: ${primaryText} !important;
        --studio-grey24: ${primaryText} !important;
        --studio-grey32: #525354 !important;
        --studio-gray: ${primaryText} !important;
        --studio-gray64: ${primaryText} !important;
        --studio-gray65: ${primaryText} !important;
        --studio-gray66: ${primaryText} !important;
        --studio-textLowInvert: '#45463F';
        --studio-transparent: 'transparent';
        --studio-gradientPrimary:
          'linear-gradient(180deg, #010203 3.6%, rgba(48, 48, 48, 0) 86.76%)';
        --studio-gradientTop:
          'linear-gradient(180deg, rgba(1, 2, 3, 0.5) 0%, rgba(27, 28, 28, 0.21875) 48.81%, rgba(48, 48, 48, 0) 86.76%)';
        --studio-gradientBottom:
          'linear-gradient(180deg, rgb(255 255 255 / 80%), rgba(27, 28, 28, 0.21875) 48.81%, rgba(48, 48, 48, 0) 86.76%)';
        --studio-gradientViewMore:
          'linear-gradient(180deg, rgba(1, 2, 3, 0) 0%, #010203 100%)';
        --studio-gradientHalf: 'linear-gradient(180deg, rgba(1, 2, 3, 0) 50%, rgba(207, 210, 195, 0.6) 100%)';
        --studio-footerDesktop:
          'linear-gradient(180deg, rgba(207, 210, 195, 0) 0%, #CFD2C3 59.58%)';
        --studio-footerMobile:
          'linear-gradient(180deg, rgba(207, 210, 195, 0) 0%, #CFD2C3 29.29%)';
        --studio-greyPrimary: ${primaryText} !important;
        --studio-backgroundBlur: 'rgba(1, 2, 3, 0.5)';

      }

      * {
        ${fontFamily}
      }

      body{
        background-color:var(--studio-backgroundColor) !important;
      }
  `;
};

const contentSection = {
  openlab: {
    collection: 'COLLECTION',
    collectionGridColumns: [2, 3, 3, 4],
    contact: 'info@openlab.fm',
    footerSocialURLs: [
      {
        socialName: 'Twitter',
        url: 'https://twitter.com/OpenLabRadio',
        icon: <TwitterIcon color="textLow" />,
      },
      {
        socialName: 'Instagram',
        url: 'https://www.instagram.com/openlabradio/',
        icon: <InstagramIcon color="textLow" />,
      },
    ],
    navLinks: [],
  },
  'the-drop': {
    collection: 'COLLECTION',
    collectionGridColumns: [2, 3, 3, 4],
    contact: 'contact@tokentraxx.com',
    footerSocialURLs: [
      {
        socialName: 'Twitter',
        url: 'https://twitter.com/thedropmovie',
        icon: <TwitterIcon color="textLow" />,
      },
      {
        socialName: 'Instagram',
        url: 'https://www.instagram.com/thedrop.movie',
        icon: <InstagramIcon color="textLow" />,
      },
    ],
    navLinks: [{title: 'Challenges', href: 'https://bit.ly/3K2uoGH'}],
  },
  'here-at-last': {
    collection: 'Collection live August 18th',
    collectionGridColumns: [1, 3, 3, 2],
    contact: 'contact@tokentraxx.com',
    footerSocialURLs: [
      {
        socialName: 'Twitter',
        url: 'https://twitter.com/hereatlastband?lang=en',
        icon: <TwitterIcon color="textDefault" />,
      },
      {
        socialName: 'Instagram',
        url: 'https://www.instagram.com/hereatlastofficial/',
        icon: <InstagramIcon color="textDefault" />,
      },
      {
        socialName: 'TikTok',
        url: 'https://www.tiktok.com/@hereatlastofficial?lang=en',
        icon: <TiktokIcon color="textDefault" />,
      },
      {
        socialName: 'Youtube',
        url: 'https://www.youtube.com/channel/UCXeJihhBSBZP7PzgQACgZfg',
        icon: <YouTubeIcon color="textDefault" />,
      },
    ],
    navLinks: [],
  },
  'my-sahel': {
    collection: 'COLLECTION',
    collectionGridColumns: [1, 3, 3, 2],
    contact: 'contact@tokentraxx.com',
    footerSocialURLs: [
      {
        socialName: 'Twitter',
        url: 'https://twitter.com/amadouetmariam?lang=en',
        icon: <TwitterIcon color="textDefault" />,
      },
      {
        socialName: 'Instagram',
        url: 'https://www.instagram.com/amadouetmariam/',
        icon: <InstagramIcon color="textDefault" />,
      },
      {
        socialName: 'Youtube',
        url: 'https://www.youtube.com/@amadouandmariam',
        icon: <YouTubeIcon color="textDefault" />,
      },
    ],
    navLinks: [],
  },
  'sample-tools-by-cr2': {
    collection: 'COLLECTION',
    collectionGridColumns: [1, 3, 3, 2],
    contact: 'contact@tokentraxx.com',
    footerSocialURLs: [
      {
        socialName: 'Twitter',
        url: 'https://twitter.com/MattSassari',
        icon: <TwitterIcon color="textDefault" />,
      },
      {
        socialName: 'Instagram',
        url: 'https://www.instagram.com/mattsassari/',
        icon: <InstagramIcon color="textDefault" />,
      },
    ],
    navLinks: [],
  },
  'npayme-marketplace': {
    artists: 'CREATORS',
    collection: 'COLLECTION',
    collectionGridColumns: [1, 3, 3, 2],
    contact: 'npayme@oneiro.io',
    footerSocialURLs: [
      {
        socialName: 'Discord',
        url: 'https://discord.com/invite/e6jx7gXHyK',
        icon: <DiscordIcon color="textDefault" />,
      },
      {
        socialName: 'Telegram',
        url: 'https://t.me/npayme_network/1',
        icon: <TelegramIcon color="textDefault" />,
      },
      {
        socialName: 'Twitter',
        url: 'https://twitter.com/npayme_network',
        icon: <TwitterIcon color="textDefault" />,
      },
    ],
    navLinks: [],
  },
  'elle-nft-collection': {
    artists: 'CREATORS',
    collection: 'COLLECTION',
    collectionGridColumns: [1, 3, 3, 4],
    contact: 'contact@tokentraxx.com',
    footerSocialURLs: [
      {
        socialName: 'LinkedIn',
        url: 'https://www.linkedin.com/company/metaminds-group/',
        icon: <LinkedinIcon color="textDefault" />,
      },
      {
        socialName: 'Youtube',
        url: 'https://www.youtube.com/@metamindsgroup',
        icon: <YouTubeIcon color="textDefault" />,
      },
    ],
    navLinks: [],
  },
  'goodwood-fractionalised-horse-ownership': {
    artists: 'CREATORS',
    collection: 'COLLECTION',
    collectionGridColumns: [1, 3, 3, 4],
    contact: 'contact@tokentraxx.com',
    footerSocialURLs: [
      {
        socialName: 'LinkedIn',
        url: 'https://www.linkedin.com/company/metaminds-group/',
        icon: <LinkedinIcon color="textDefault" />,
      },
      {
        socialName: 'Youtube',
        url: 'https://www.youtube.com/@metamindsgroup',
        icon: <YouTubeIcon color="textDefault" />,
      },
    ],
    navLinks: [],
    primaryButton: '#D4A373', // Light brown for button
    primaryButtonText: '#FFFFFF', // White text
  }
};

export const useStudioProperties = () => {
  const router = useRouter();
  const [themeColor, setThemeColor] = useState({
    primaryButton: '#FF6C74',
    primaryButtonText: '#fff',
    secondaryButton: '#FF6C74',
    secondaryButtonText: '#fff',
    backgroundColor: '#fff',
    primaryText: '#53524A',
    secondaryText: '#838383',
    footerBackground: '#B0B1A9',
    footerText: '#1F2021',
    logoUrl: 'https://openlab.fm/img/openlab-logo.svg',
    fontFamily: '',
  });
  const [studioContent, setStudioContent] = useState({
    artists: 'ARTISTS',
    collection: 'COLLECTION',
    collectionGridColumns: [2, 3, 3, 4],
    contact: 'contact@tokentraxx.com',
    footerSocialURLs: [
      {
        socialName: 'Twitter',
        url: 'https://twitter.com/OpenLabRadio',
        icon: <TwitterIcon color="textLow" />,
      },
      {
        socialName: 'Instagram',
        url: 'https://www.instagram.com/openlabradio/',
        icon: <InstagramIcon color="textLow" />,
      },
    ],
    navLinks: [] as Array<{title: string; href: string}>,
  });
  const {pathname, query} = router;
  const {slug = '', artistsslug = ''} = query;
  const isStudioPage = STUDIOS_PAGES.includes(pathname);
  if (router.asPath.includes('/multidrop-form?network') || !isStudioPage)
    return {
      themeColor,
      cssClasses: generateCssClasses(themeColor),
      studioContent,
    };

  const {isLoading, isSuccess, data} = useTtxQuery(
    [
      'evm.get-studio-theme',
      {
        slug: Array.isArray(slug) ? slug[0] : slug,
        artists: 'none',
      },
    ],
    {},
  );

  useEffect(() => {
    if (data && isSuccess && data.customisation && slug) {
      const slugKey = Array.isArray(slug) ? slug[0] : slug;
      let fontFamily = '';
      if (slug === 'openlab') {
        fontFamily = 'font-family: "OpenLab Normal" !important';
      } else if (slug === 'elle-nft-collection') {
        fontFamily = 'font-family: "Montserrat Normal" !important';
      }
      else if (slug === 'goodwood-fractionalised-horse-ownership') {
        fontFamily = 'font-family: "Neue Haas Grotesk" !important';
    }
      // console.log("data of susyokdo---
       
      setThemeColor({
        ...data.customisation,
        fontFamily,
      });
      setStudioContent(contentSection[slugKey as keyof typeof contentSection]);
    }
  }, [data, slug]);





  return {
    themeColor,
    cssClasses: generateCssClasses(themeColor),
    isLoading,
    studioContent,
  };
};
