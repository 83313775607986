import React, {PropsWithChildren, useMemo, useCallback, useRef} from 'react';
import {
  isWeb3Provider,
  Address,
  toNativeCurrencyString,
  BaseProvider,
  BaseContract,
  ContractInterface,
  WebSocketProvider,
  InfuraProvider,
  FormattableCurrency,
  TraxxStemzContract,
  JoshSavageContract,
  EmilGContract,
  JsonRpcProvider,
} from '@ttx/core';
import {usePlausible} from 'next-plausible';
import {SmartContractContext} from './context';
import {
  NftContract as NftContractType,
  TraxxContract as TraxxContractType,
  MarketplaceContract as MarketplaceContractType,
  ContractName,
  MultiNFTContract,
  TraxxStemzContract as TraxxStemzContractType,
  JoshSavageContract as JoshSavageContractType,
  EmilGContract as EmilGContractType,
} from './types';
import {useTtxQuery} from '../../hooks/ttx-trpc';
import {useAuthenticationContext} from '../authentication-context';
import {areAddressesEqual, getBlockchainNetworkDetailForNetwork} from './utils';
import {useCachedState} from '../../hooks/use-cached-state';
import {PaymentToken} from '../../server/router/types';
import {useGetSmartContract} from './use-get-smart-contract';
import {WalletNetwork} from '../authentication-context/types';
import {
  ethereumProviderNames,
  ethereumRpc,
  polygonRpc,
  polygonWebSocketName,
  polygonWebSocketRpc,
} from '../authentication-context/consts';
import {mixpanelTrack} from '../analytics-context/utils';
import {useLifiWidget} from '../lifi-context';

export const SmartContractProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    walletAddress,
    walletNetwork,
    _web3React: {library: web3ReactLibrary},
  } = useAuthenticationContext();
  const polygonSockerProviderRef = useRef<BaseProvider | null>(null);
  const ethereumSockerProviderRef = useRef<BaseProvider | null>(null);
  const infuraProviderRef = useRef<BaseProvider | null>(null);
  const plausible = usePlausible();

  const {isExchangeCompleted} = useLifiWidget();

  const {data: networksData} = useTtxQuery(['traxx.getNetworks']);

  const tokenTraxxContracts = useGetSmartContract({
    network: walletNetwork || undefined,
  });

  const contractABIPolygon = useGetSmartContract({
    network: 'polygon',
  });

  const contractABIEthereum = useGetSmartContract({
    network: 'ethereum',
  });

  const contractABIZilliqa = useGetSmartContract({
    network: 'zilliqa',
  });

  const getSigner = useCallback(
    async (network: WalletNetwork) => {
      const loadedLibrary = await web3ReactLibrary;
      if (!loadedLibrary) return null;
      if (!walletAddress) return null;
      if (!isWeb3Provider(loadedLibrary)) return null;
      if (network !== walletNetwork) return null;
      return loadedLibrary.getSigner(walletAddress);
    },
    [walletAddress, web3ReactLibrary, walletNetwork],
  );

  const getProvider: ({
    network,
    chainId,
  }: {
    network: WalletNetwork;
    chainId: number;
  }) => Promise<BaseProvider> = async ({network, chainId}) => {
    console.log(network, chainId);
    if (network === 'polygon') {
      if (!polygonSockerProviderRef.current) {
        polygonSockerProviderRef.current = new JsonRpcProvider(
          polygonRpc[chainId as keyof typeof polygonRpc],
          chainId,
        );
      }
      return polygonSockerProviderRef.current;
    }
    if (network === 'ethereum') {
      if (!ethereumSockerProviderRef.current) {
        ethereumSockerProviderRef.current = new JsonRpcProvider(
          ethereumRpc[chainId as keyof typeof ethereumRpc],
          chainId,
        );
      }
      return ethereumSockerProviderRef.current;
    }
    if (!infuraProviderRef.current) {
      infuraProviderRef.current = new InfuraProvider(
        ethereumProviderNames[chainId as keyof typeof ethereumProviderNames],
        process.env.INFURA_PROJECT_ID,
      );
    }
    return infuraProviderRef.current;
  };

  const getContractByAddress = useCallback(
    async <TokenTraxxContract extends any>(
      address: Address,
      contractType: ContractName,
      contractABI: {data: {[k: string]: ContractInterface}},
      network: WalletNetwork,
    ) => {
      if (!networksData) return null;
      if (!contractABI || !contractABI.data) return null;

      const networkData = networksData.find((item) => item.network === network);

      if (!networkData?.rpcUrl || !networkData?.chainId) return null;

      const {Contract} = await import('@ttx/core');

      const signer = await getSigner(network);

      const provider = await getProvider({
        network,
        chainId: networkData.chainId,
      });

      switch (contractType) {
        case 'nftAddress': {
          return new Contract(
            address,
            contractABI.data.nft,
            signer || provider,
          ) as unknown as BaseContract & TokenTraxxContract;
        }
        case 'marketPlaceAddress': {
          return new Contract(
            address,
            contractABI.data.market,
            signer || provider,
          ) as unknown as BaseContract & TokenTraxxContract;
        }
        case 'multiCopyNFTAddress': {
          return new Contract(
            address,
            contractABI.data.multiNFT,
            signer || provider,
          ) as unknown as BaseContract & TokenTraxxContract;
        }
        case 'traxxStemzAddress': {
          return new Contract(
            process.env.TRAXX_CONTRACT_ADDRESS as string,
            TraxxStemzContract.abi,
            signer || provider,
          ) as unknown as BaseContract & TokenTraxxContract;
        }
        case 'joshSavageAddress': {
          return new Contract(
            process.env.JOSH_CONTRACT as string,
            JoshSavageContract,
            signer || provider,
          ) as unknown as BaseContract & TokenTraxxContract;
        }
        case 'emilGAddress': {
          return new Contract(
            process.env.EMILG_CONTRACT_ADDRESS as string,
            EmilGContract,
            signer || provider,
          ) as unknown as BaseContract & TokenTraxxContract;
        }
        default:
          return null;
      }
    },
    [networksData, getSigner],
  );

  const getContractByName = useCallback(
    async <TokenTraxxContract extends any>(
      contractName: ContractName,
      network: WalletNetwork,
    ) => {
      if (!networksData) return null;

      const blockchainNetworkDetail = getBlockchainNetworkDetailForNetwork(
        network,
        networksData,
      );

      // TODO: refactor once get abi endpoint supports traxxstemz
      if (contractName === 'traxxStemzAddress') {
        const contractByAddress =
          await getContractByAddress<TokenTraxxContract>(
            process.env.TRAXX_CONTRACT_ADDRESS as `0x${string}`,
            'traxxStemzAddress',
            {data: TraxxStemzContract.abi} as any,
            network,
          );

        return contractByAddress;
      }

      if (contractName === 'joshSavageAddress') {
        const contractByAddress =
          await getContractByAddress<TokenTraxxContract>(
            process.env.JOSH_CONTRACT as `0x${string}`,
            'joshSavageAddress',
            {data: JoshSavageContract} as any,
            network,
          );

        return contractByAddress;
      }

      if (contractName === 'emilGAddress') {
        const contractByAddress =
          await getContractByAddress<TokenTraxxContract>(
            process.env.EMILG_CONTRACT_ADDRESS as `0x${string}`,
            'emilGAddress',
            {data: EmilGContract} as any,
            network,
          );

        return contractByAddress;
      }

      if (!blockchainNetworkDetail) return null;
      const contractAddress = blockchainNetworkDetail[contractName];

      if (!contractAddress) return null;

      const contractAbi = () => {
        switch (network) {
          case 'ethereum': {
            return contractABIEthereum;
          }
          case 'polygon': {
            return contractABIPolygon;
          }
          case 'zilliqa': {
            return contractABIZilliqa;
          }
          default:
            return null;
        }
      };

      const abi = contractAbi();

      if (!abi) return null;

      const contractByAddress = await getContractByAddress<TokenTraxxContract>(
        contractAddress,
        contractName,
        abi,
        network,
      );

      return contractByAddress;
    },
    [
      contractABIEthereum,
      contractABIPolygon,
      contractABIZilliqa,
      getContractByAddress,
      networksData,
    ],
  );

  const getPaymentTokenContract = useCallback(
    async (address: Address) => {
      if (!walletAddress) return null;
      if (!networksData) return null;
      if (!walletNetwork) return null;
      if (!tokenTraxxContracts?.data?.traxx) return null;

      const blockchainNetworkDetail = getBlockchainNetworkDetailForNetwork(
        walletNetwork,
        networksData,
      );
      if (!blockchainNetworkDetail) return null;

      const networkData = networksData.find(
        (item) => item.network === walletNetwork,
      );

      if (!networkData?.chainId) return null;

      const {Contract} = await import('@ttx/core');

      const signer = await getSigner(walletNetwork);
      const provider = await getProvider({
        network: walletNetwork,
        chainId: networkData.chainId,
      });

      return new Contract(
        address,
        tokenTraxxContracts?.data?.traxx,
        signer || provider,
      ) as unknown as BaseContract & TraxxContractType;
    },
    [
      getSigner,
      networksData,
      tokenTraxxContracts?.data?.traxx,
      walletAddress,
      walletNetwork,
    ],
  );

  const traxxStemzContract = useCallback(
    async (network: WalletNetwork) => {
      return getContractByName<TraxxStemzContractType>(
        'traxxStemzAddress',
        network,
      );
    },
    [getContractByName],
  );

  const joshSavageContract = useCallback(
    async (network: WalletNetwork) => {
      return getContractByName<JoshSavageContractType>(
        'joshSavageAddress',
        network,
      );
    },
    [getContractByName],
  );

  const emilGContract = useCallback(
    async (network: WalletNetwork) => {
      return getContractByName<EmilGContractType>('emilGAddress', network);
    },
    [getContractByName],
  );

  const nftContract = useCallback(
    async (network: WalletNetwork) => {
      return getContractByName<NftContractType>('nftAddress', network);
    },
    [getContractByName],
  );

  const marketplaceContract = useCallback(
    async (network: WalletNetwork) => {
      return getContractByName<MarketplaceContractType>(
        'marketPlaceAddress',
        network,
      );
    },
    [getContractByName],
  );

  const multiNFTContract = useCallback(
    async (network: WalletNetwork) => {
      return getContractByName<MultiNFTContract>(
        'multiCopyNFTAddress',
        network,
      );
    },
    [getContractByName],
  );

  const getCurrencySymbolFromAddress = useCallback(
    (address: Address, network: WalletNetwork): FormattableCurrency | null => {
      if (!networksData) return null;

      const networkForItem = networksData
        .filter((d) => d.network === network)
        .pop();

      const currencySymbol =
        networkForItem?.supportedPaymentToken?.[address]?.symbol || null;

      return currencySymbol;
    },
    [networksData],
  );

  const getMaxCurrencyAllowanceFromAddress = useCallback(
    (address: Address): `${number}` | null => {
      if (!networksData) return null;

      let requestableAllowance: `${number}` | null = null;

      networksData.forEach(({supportedPaymentToken}) => {
        if (!supportedPaymentToken) return;
        const untypedSupportedPaymentToken = supportedPaymentToken as Record<
          string,
          PaymentToken
        >;

        const matchedPaymentTokenAddress = (
          Object.keys(supportedPaymentToken) as Address[]
        )
          .filter((paymentTokenAddress) =>
            areAddressesEqual(paymentTokenAddress, address),
          )
          .shift();
        if (!matchedPaymentTokenAddress) return;

        if (untypedSupportedPaymentToken[matchedPaymentTokenAddress]) {
          requestableAllowance =
            untypedSupportedPaymentToken[matchedPaymentTokenAddress]
              .maxAllowance;
        }
      });

      return requestableAllowance;
    },
    [networksData],
  );

  const getCanAskForAllowance = useCallback(
    async (currencyAddress: Address) => {
      if (!walletNetwork || !networksData)
        throw new Error('No networks data found');

      const blockchainNetworkDetail = getBlockchainNetworkDetailForNetwork(
        walletNetwork,
        networksData,
      );

      if (!blockchainNetworkDetail)
        throw new Error('No blockchain network detail found');
      if (!blockchainNetworkDetail.supportedPaymentToken) {
        plausible('Payment token missing');
        mixpanelTrack('Payment Tokens Missing');
        throw new Error('No supported payment tokens');
      }

      return blockchainNetworkDetail.supportedPaymentToken[currencyAddress]
        .askForAllowance;
    },
    [networksData, walletNetwork, plausible],
  );

  const updateSupportedPaymentTokenBalances = useCallback(async () => {
    if (!walletNetwork) return [];
    if (!networksData) return [];

    const blockchainNetworkDetail = getBlockchainNetworkDetailForNetwork(
      walletNetwork,
      networksData,
    );

    if (!blockchainNetworkDetail) return [];

    // This means the API doesn't support payments for this network
    // If this is the case, it is probably a bug...
    if (!blockchainNetworkDetail.supportedPaymentToken) return [];

    const supportedPaymentTokens =
      blockchainNetworkDetail.supportedPaymentToken;

    const supportedPaymentTokenKeys = Object.keys(
      supportedPaymentTokens,
    ) as (keyof typeof supportedPaymentTokens)[];

    const balances = Promise.all(
      supportedPaymentTokenKeys.map(async (contractAddress) => {
        const paymentToken = supportedPaymentTokens[
          contractAddress
        ] as PaymentToken;

        if (!paymentToken.askForAllowance) return null;

        const paymentTokenContract =
          await getPaymentTokenContract(contractAddress);

        const balance =
          paymentTokenContract && walletAddress
            ? await paymentTokenContract.balanceOf(walletAddress)
            : null;

        const formattedBalance = balance
          ? toNativeCurrencyString(balance, paymentToken.symbol)
          : null;

        return {
          ...paymentToken,
          contractAddress,
          balance: formattedBalance,
        };
      }),
    );

    return (await balances).filter(Boolean) as (PaymentToken & {
      contractAddress: Address;
      balance: string | null;
    })[];
  }, [
    getPaymentTokenContract,
    networksData,
    walletAddress,
    walletNetwork,
    isExchangeCompleted,
  ]);

  const supportedPaymentTokenBalances = useCachedState(
    updateSupportedPaymentTokenBalances,
    [],
  );

  const value = useMemo(
    () => ({
      nftContract,
      getPaymentTokenContract,
      getCurrencySymbolFromAddress,
      getMaxCurrencyAllowanceFromAddress,
      getCanAskForAllowance,
      supportedPaymentTokenBalances,
      multiNFTContract,
      contractABIEthereum,
      marketplaceContract,
      traxxStemzContract,
      joshSavageContract,
      emilGContract,
    }),
    [
      nftContract,
      getCanAskForAllowance,
      getPaymentTokenContract,
      getCurrencySymbolFromAddress,
      getMaxCurrencyAllowanceFromAddress,
      supportedPaymentTokenBalances,
      multiNFTContract,
      contractABIEthereum,
      marketplaceContract,
      traxxStemzContract,
      joshSavageContract,
      emilGContract,
    ],
  );

  return (
    <SmartContractContext.Provider value={value}>
      {children}
    </SmartContractContext.Provider>
  );
};
