import {AtRule} from '@stitches/react/types/css';
import React from 'react';
import {
  getCssText,
  globalCss,
  stitchesTheme,
} from '../stitches/stitches.config';
import {TokenTraxxThemeProviderProps} from './types';

interface GenerateFontFaceArg {
  fontFamily: string;
  fileName: string;
  fontWeight: number;
  fontStyle: AtRule.FontStyle;
}

const generateFontFace = ({
  fileName,
  fontFamily,
  fontStyle,
  fontWeight,
}: GenerateFontFaceArg): AtRule.FontFace => ({
  fontFamily,
  src: `url(/fonts/${fileName}) format('${fileName.split('.').pop()}')`,
  fontStyle,
  fontWeight,
  fontDisplay: 'swap',
});

const globalStyles = globalCss({
  body: {
    backgroundColor: stitchesTheme.colors.surfaceDark,
    height: '100%',
    overflowX: 'hidden',
  },
  html: {
    height: '100%',
  },
  '#__next': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  '@font-face': [
    // Normal
    {
      fontFamily: 'Altone',
      fileName: 'Altone-Regular.woff2',
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      fontFamily: 'Altone',
      fileName: 'Altone-RegularOblique.woff2',
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      fontFamily: 'ABC Diatype',
      fileName: 'ABCDiatype-Regular.woff2',
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      fontFamily: 'ABC Diatype Semi Mono',
      fileName: 'ABCDiatypeSemi-Mono-Regular.woff2',
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      fontFamily: 'OpenLab Normal',
      fileName: 'openlab-regular-webfont.woff',
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      fontFamily: 'Montserrat Normal',
      fileName: 'montserrat-regular.woff2',
      fontStyle: 'normal',
      fontWeight: 400,
    },

    // Bold
    {
      fontFamily: 'Altone',
      fileName: 'Altone-Bold.woff2',
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      fontFamily: 'Altone',
      fileName: 'Altone-BoldOblique.woff2',
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      fontFamily: 'ABC Diatype',
      fileName: 'ABCDiatypeSemi-Mono-Bold.woff2',
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      fontFamily: 'OpenLab Bold',
      fileName: 'openlab-bold-regular-webfont.woff',
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      fontFamily: 'Montserrat Bold',
      fileName: 'montserrat-bold.woff2',
      fontStyle: 'bold',
      fontWeight: 700,
    },
    {
      fontFamily: 'Neue Haas Grotesk',
      fileName: 'NeueHaasDisplayLight.woff2',
      fontStyle: 'normal',
      fontWeight: 400,
    },
  ].map(generateFontFace),
});

export const TokenTraxxThemeProvider: React.FC<
  TokenTraxxThemeProviderProps
> = ({children}) => (
  <>
    {globalStyles()}
    {children}
  </>
);

export const ThemeStyle: React.FC = () => (
  // eslint-disable-next-line react/no-danger
  <style id="stitches" dangerouslySetInnerHTML={{__html: getCssText()}} />
);
