/* eslint-disable import/no-cycle */
import {AbstractConnector} from '@web3-react/abstract-connector';
import {VenlyChains, ZILLIQA_DEV_TEST_NET} from '@ttx/core';
import {WindowMode} from '@venly/connect';
import {Chain} from '@ttx/core/src/withpaper/types';
import {ethereumRpc, polygonRpc} from './consts';
import {Network, WalletRegistrationSource} from './types';

export type Environment = 'production' | 'preview' | 'local';

export const ENVIRONMENT: Environment =
  (process.env.NEXT_PUBLIC_VERCEL_ENV as any) ?? 'local';

type WalletRegistrationSourceConnectorMap<Connector extends AbstractConnector> =
  {
    [key in WalletRegistrationSource]: (
      network?: Network,
      isSwitched?: boolean,
    ) => Promise<Connector> | Connector;
  };

const registrationSourceConnectorMap: WalletRegistrationSourceConnectorMap<AbstractConnector> =
  {
    metaMask: async () => {
      const {InjectedConnector} = await import(
        '@web3-react/injected-connector'
      );

      return new InjectedConnector({
        supportedChainIds:
          ENVIRONMENT === 'production'
            ? [1, 137]
            : [3, 4, 5, 42, 80002, 11155111],
      });
    },
    walletConnect: async () => {
      const {WalletConnectConnector} = await import(
        '@web3-react/walletconnect-connector'
      );

      return new WalletConnectConnector({
        qrcode: true,
        rpc: {...polygonRpc, ...ethereumRpc},
        supportedChainIds:
          ENVIRONMENT === 'production'
            ? [1, 137]
            : [3, 4, 5, 42, 80002, 11155111],
      });
    },
    magicLinkEmail: async () => {
      throw new Error('Not implemented');
    },
    magicLinkSMS: async () => {
      throw new Error('Not implemented');
    },
    venly: async (chain = 'ETHEREUM', isSwitched = false) => {
      const {VenlyConnector} = await import('@ttx/core');
      return new VenlyConnector(process.env.VENLY_CLIENT_ID as string, {
        chains: [chain as VenlyChains],
        environment: process.env.VENLY_ENVIROMENT as string,
        windowMode: WindowMode.REDIRECT,
        isSwitched,
      });
    },
    zilPay: async () => {
      const {ZilPayConnector} = await import('@ttx/core');
      const fallbackChain = ZILLIQA_DEV_TEST_NET;
      if (!process.env.ZILLIQA_CHAIN) {
        console.warn(
          `No ZILLIQA_CHAIN environment variable set... Using chain: ${fallbackChain} - if you want a different chain, set the ZILLIQA_CHAIN environment variable.`,
        );
      }
      return new ZilPayConnector(process.env.ZILLIQA_CHAIN || fallbackChain);
    },
    paper: async (chain = 'MATIC', isSwitched = false) => {
      const {PaperConnector} = await import('@ttx/core');
      // update chain for testnet, by default configured it for mainnet
      return new PaperConnector(process.env.PAPER_WALLET_CLIENT_ID as string, {
        chain: (chain === 'ETHEREUM' ? 'Ethereum' : 'Polygon') as Chain,
        isSwitched,
      });
    },
  };

export const getConnectorForWalletRegistrationSource = async <
  Connector extends AbstractConnector,
>(
  registrationSource: WalletRegistrationSource,
  network: Network = 'ETHEREUM',
  isSwitched = false,
): Promise<Connector> => {
  if (
    !Object.keys(registrationSourceConnectorMap).includes(registrationSource)
  ) {
    throw new Error(`Unrecognized registration source: ${registrationSource}`);
  }
  return registrationSourceConnectorMap[registrationSource](
    network,
    isSwitched,
  ) as any;
};
