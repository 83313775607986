import {GetServerSidePropsContext} from 'next';
import nookies from 'nookies';
import {WalletState} from '../../contexts/authentication-context/types';
import {TrpcContext} from '../router/context';
import {
  ACCESS_TOKEN_COOKIE_NAME,
  AUTHENTICATED_SIGNATURE_STATE,
  AUTHENTICATED_WALLET_ID_STATE,
  AUTHENTICATED_WALLET_INFO_STATE,
  AUTHENTICATED_WALLET_STATE_COOKIE_NAME,
} from './consts';
import {CookieName} from './types';

interface SetServerSideOnlyCookieOptions {
  maxAge?: number;
  path?: string;
  httpOnly?: boolean;
  secure?: boolean;
}

export const setCookie = (
  ctx: TrpcContext | null,
  cookieKey: CookieName,
  cookieValue: string,
  options?: SetServerSideOnlyCookieOptions,
  maxAge = 3600, // 1 hour
): void => {
  nookies.set(ctx, cookieKey, cookieValue, {
    maxAge,
    path: '/',
    ...options,
    sameSite: process.env.NODE_ENV !== 'development' ? 'strict' : undefined,
    secure: process.env.NODE_ENV === 'production',
  });
};
export const setServerSideOnlyCookie = (
  ctx: TrpcContext,
  cookieKey: CookieName,
  cookieValue: string,
  options?: SetServerSideOnlyCookieOptions,
): void => {
  setCookie(ctx, cookieKey, cookieValue, {
    ...options,
    httpOnly: true,
  });
};

export const destroyCookie = (
  ctx: TrpcContext,
  cookieKey: CookieName,
): void => {
  nookies.destroy(ctx, cookieKey, {
    path: '/',
  });
};

interface SetAuthCookiesOptions {
  accessToken: string;
  walletState: WalletState;
}

export const setAuthCookies = (
  ctx: TrpcContext,
  {accessToken, walletState}: SetAuthCookiesOptions,
): void => {
  setServerSideOnlyCookie(ctx, ACCESS_TOKEN_COOKIE_NAME, accessToken);
  setCookie(
    ctx,
    AUTHENTICATED_WALLET_STATE_COOKIE_NAME,
    JSON.stringify(walletState),
  );
};

export const clearAuthCookies = (ctx: TrpcContext): void => {
  destroyCookie(ctx, AUTHENTICATED_WALLET_STATE_COOKIE_NAME);
  destroyCookie(ctx, ACCESS_TOKEN_COOKIE_NAME);
  destroyCookie(ctx, AUTHENTICATED_WALLET_ID_STATE);
  destroyCookie(ctx, AUTHENTICATED_WALLET_INFO_STATE);
  destroyCookie(ctx, AUTHENTICATED_SIGNATURE_STATE);
};

export const getCookie = (
  ctx: TrpcContext | GetServerSidePropsContext | null,
  cookieKey: CookieName,
): string | undefined => nookies.get(ctx)[cookieKey];