/* eslint-disable import/no-cycle */
import React, {useState} from 'react';
import {useSpring} from '../..';
import {AnimatedBox, Box} from '../../box';
import {FrameworkImage} from '../../image-renderer';
import {Typography} from '../typography';

export interface CollectionImageProps {
  onlyImage: boolean;
  imageSrc: string;
  nftCount?: number | null;
  isLeft?: boolean;
  isMobile: boolean;
  is1200px?: boolean | null | undefined;
  is960px?: boolean | null | undefined;
  is768px?: boolean | null | undefined;
  hideCollectionCount?: boolean | null | undefined;
}

export const CollectionLeftImage: React.FC<CollectionImageProps> = ({
  onlyImage,
  imageSrc,
  nftCount,
  isMobile,
  is1200px,
  is960px,
  is768px,
  hideCollectionCount,
}: CollectionImageProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const collImage1 = useSpring({
    left: isHovered && !isMobile ? '-16px' : '0px',
    config: {
      duration: 200,
    },
  });
  const collImage3 = useSpring({
    left: isHovered && !isMobile ? '22px' : '16px',
    config: {
      duration: 200,
    },
  });
  const heightWidth = is1200px ? {w: 480, h: 416} : {w: 346, h: 288};
  return (
    <Box
      position="relative"
      onHoverIn={() => setIsHovered(!onlyImage)}
      onHoverOut={() => setIsHovered(false)}
    >
      {!onlyImage && !hideCollectionCount && (
        <>
          <AnimatedBox
            maxWidth="100%"
            height={heightWidth.h - 48}
            width={heightWidth.w}
            borderRadius="two"
            borderWidth="one"
            borderStyle="solid"
            position="absolute"
            top="24px"
            style={{...collImage1}}
            css={{
              borderColor: 'var(--studio-outlines)',
              backgroundColor: 'var(--studio-surfaceDark)',
            }}
          />
          <Box
            maxWidth="100%"
            height={heightWidth.h - 24}
            width={heightWidth.w}
            borderRadius="two"
            borderWidth="one"
            borderStyle="solid"
            position="absolute"
            top="12px"
            left="0px"
            css={{
              borderColor: 'var(--studio-outlines)',
              backgroundColor: 'var(--studio-surfaceDark)',
            }}
          />
        </>
      )}
      <AnimatedBox
        maxWidth="100%"
        height={heightWidth.h}
        width={['100%', heightWidth.w]}
        borderRadius="two"
        borderWidth="one"
        borderStyle="solid"
        position="absolute"
        top="0px"
        overflow="hidden"
        style={{...collImage3}}
        css={{
          borderColor: 'var(--studio-outlines)',
          backgroundColor: 'var(--studio-surfaceDark)',
        }}
      >
        <FrameworkImage
          src={imageSrc || ''}
          alt={imageSrc}
          layout="fill"
          objectFit="cover"
        />
      </AnimatedBox>

      {!onlyImage && nftCount !== 0 && (
        <AnimatedBox
          style={{
            width: '120px',
            height: '18px',
            borderRadius: 18,
            ...collImage3,
          }}
          marginTop="four"
          marginLeft={['three', 'zero']}
          css={{backgroundColor: 'var(--studio-secondaryButton)'}}
        >
          <Typography
            fontSize="s"
            css={{color: 'var(--studio-secondaryButtonText)'}}
            textAlign="center"
          >
            Collection of {nftCount}
          </Typography>
        </AnimatedBox>
      )}
    </Box>
  );
};

export const CollectionRightImage: React.FC<CollectionImageProps> = ({
  onlyImage,
  imageSrc,
  nftCount,
  isMobile,
  is1200px,
  is960px,
  is768px,
  hideCollectionCount,
}: CollectionImageProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const collImage1 = useSpring({
    left: isHovered && !isMobile ? '48px' : '16px',
    config: {
      duration: 200,
    },
  });
  const collImage3 = useSpring({
    left: isHovered && !isMobile ? '-8px' : '0px',
    config: {
      duration: 200,
    },
  });
  const heightWidth = is1200px ? {w: 480, h: 416} : {w: 346, h: 288};
  return (
    <Box
      paddingX={['zero', 'lg']}
      position="relative"
      onHoverIn={() => setIsHovered(!onlyImage)}
      onHoverOut={() => setIsHovered(false)}
    >
      {!onlyImage && !hideCollectionCount && (
        <>
          <AnimatedBox
            height={heightWidth.h - 48}
            width={heightWidth.w - 20}
            borderRadius="two"
            borderWidth="one"
            borderStyle="solid"
            position="absolute"
            top="24px"
            style={{...collImage1}}
            css={{
              borderColor: 'var(--studio-outlines)',
              backgroundColor: 'var(--studio-surfaceDark)',
            }}
          />
          <Box
            height={heightWidth.h - 24}
            width={heightWidth.w - 10}
            borderRadius="two"
            borderWidth="one"
            borderStyle="solid"
            position="absolute"
            top="12px"
            left="24px"
            css={{
              borderColor: 'var(--studio-outlines)',
              backgroundColor: 'var(--studio-surfaceDark)',
            }}
          />
        </>
      )}
      <AnimatedBox
        height={heightWidth.h}
        width={['100%', heightWidth.w]}
        borderRadius="two"
        borderWidth="one"
        borderStyle="solid"
        position="absolute"
        top="0px"
        overflow="hidden"
        style={{...collImage3}}
        css={{
          borderColor: 'var(--studio-outlines)',
          backgroundColor: 'var(--studio-surfaceDark)',
        }}
      >
        <FrameworkImage
          src={imageSrc || ''}
          alt={imageSrc}
          layout="fill"
          objectFit="cover"
        />
      </AnimatedBox>

      {!onlyImage && nftCount !== 0 && (
        <AnimatedBox
          style={{
            width: '120px',
            height: '18px',
            borderRadius: 18,
            ...collImage3,
          }}
          marginTop="four"
          marginLeft={['three', 'zero']}
          css={{backgroundColor: 'var(--studio-secondaryButton)'}}
        >
          <Typography
            fontSize="s"
            css={{color: 'var(--studio-secondaryButtonText)'}}
            textAlign="center"
          >
            Collection of {nftCount}
          </Typography>
        </AnimatedBox>
      )}
    </Box>
  );
};

export const CollectionImage: React.FC<CollectionImageProps> = ({
  onlyImage = false,
  imageSrc,
  nftCount,
  isLeft,
  isMobile,
  is1200px,
  is960px,
  is768px,
  hideCollectionCount,
}: CollectionImageProps) => {
  if (isLeft)
    return (
      <CollectionLeftImage
        onlyImage={onlyImage}
        imageSrc={imageSrc}
        isMobile={isMobile}
        nftCount={nftCount}
        is1200px={is1200px}
        is960px={is960px}
        is768px={is768px}
        hideCollectionCount={hideCollectionCount}
      />
    );
  return (
    <CollectionRightImage
      onlyImage={onlyImage}
      imageSrc={imageSrc}
      isMobile={isMobile}
      nftCount={nftCount}
      is1200px={is1200px}
      is960px={is960px}
      is768px={is768px}
      hideCollectionCount={hideCollectionCount}
    />
  );
};
